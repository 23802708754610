import { Helmet } from 'react-helmet';

export const ReactHelmet = () => {
  return (
    <Helmet>
      <title>Thundering Herd | London Ultimate Frisbee</title>
      <meta name="description" content="London's best (-dressed) ultimate frisbee team. We welcome beginners and international-level players alike. Playing hard and partying responsibly since 2003" />
      <meta name="author" content="@herdultimate" />
  </Helmet>
  )
}