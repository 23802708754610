import GoogleMapReact from 'google-map-react'
import { Icon } from '@iconify/react'
import cowIcon from '@iconify-icons/whh/cow'

const LocationPin = ({ text }) => (
  <div className="pin">
    <Icon icon={cowIcon} className="pin-icon" />
    <p className="pin-text">{text}</p>
  </div>
);

export const Map = ({ location, zoomLevel }) => {

  // This also works and with less permissions, but doesn't look quite as nice:

  // const mapsUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyDQzGNRTxPtNRk2mh2rjy2VDNOaEYs0u1k&q=Clapham+Common+Fitness+Area+2`;

  // <iframe
  //   frameborder="0" style={{ border: 0, width: "100%", height: "100%" }}
  //   referrerpolicy="no-referrer-when-downgrade"
  //   src={mapsUrl}
  //   allowfullscreen>
  // </iframe>

  return(
    <div id='training_map'>
      <div className="google-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDQzGNRTxPtNRk2mh2rjy2VDNOaEYs0u1k" }}
          defaultCenter={location}
          defaultZoom={zoomLevel}
        >
        <LocationPin
          lat={location.lat}
          lng={location.lng}
          text={location.address}
        />
        </GoogleMapReact>
      </div>
    </div>
  )
}
