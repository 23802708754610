export const Calendar = () => {
  return (
    <div id='calendar'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>Calendar</h2>
        </div>
        <div className='row text-center'>
          <iframe title="Thunder Herd Public Calendar" src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FLondon&showTitle=0&showNav=1&showDate=1&showPrint=0&showTabs=1&showCalendars=0&showTz=0&src=a3VndW5qdHZmdGNtc2k0dXQzOWs1Yzl1NmNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23D50000"></iframe>
        </div>
      </div>
    </div>
  )
}