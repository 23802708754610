export const Gallery = (props) => {
  return (
    <div id='gallery' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Gallery</h2>
          <p>
            Fit on the pitch. Fitter off the pitch. Karaoke champions and
            Eurovision megafans.
          </p>
          <p>Unofficial Beach Nationals champions 2022.</p>
        </div>
        <div className='row'>
          <div className='gallery-items'>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='gallery-item'>
                <div className='hover-bg'>
                  <div className='hover-text'>
                    <h4>We're also indoor cows</h4>
                  </div>
                  <img
                    srcset='img/gallery/01-CXIR-small.jpeg 480w, img/gallery/01-CXIR.jpeg 1080w'
                    sizes='50vw'
                    className='img-gallery'
                    alt='Herd at Club Mixed Indoor Regionals'
                  />
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='gallery-item'>
                <div className='hover-bg'>
                  <div className='hover-text'>
                    <h4>Lavender Fresh</h4>
                  </div>
                  <img
                    srcset='img/gallery/02-Lavender-Hike-small.jpeg 480w, img/gallery/02-Lavender-Hike.jpeg 1080w'
                    sizes='50vw'
                    className='img-gallery'
                    alt='Hike around kent. Team in front of lavender nursery'
                  />
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='gallery-item'>
                <div className='hover-bg'>
                  <div className='hover-text'>
                    <h4>Calafell</h4>
                  </div>
                  <img
                    srcset='img/gallery/03-Calafell-small.jpeg 480w, img/gallery/03-Calafell.jpeg 1080w'
                    sizes='50vw'
                    className='img-gallery'
                    alt='Team at Calafell'
                  />
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='gallery-item'>
                <div className='hover-bg'>
                  <div className='hover-text'>
                    <h4>Mega Mini City Beach Ulti</h4>
                  </div>
                  <img
                    srcset='img/gallery/04-London-Beach-Sunset-small.jpeg 480w, img/gallery/04-London-Beach-Sunset.jpeg 1080w'
                    sizes='50vw'
                    className='img-gallery'
                    alt='Mega Mini Beach Ultimate beneath the Shard'
                  />
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='gallery-item'>
                <div className='hover-bg'>
                  <div className='hover-text'>
                    <h4>Dress to Impress</h4>
                  </div>
                  <img
                    srcset='img/gallery/05-AGM-small.jpeg 480w, img/gallery/05-AGM.jpeg 1080w'
                    sizes='50vw'
                    className='img-gallery'
                    alt='Dress to impress at the AGM'
                  />
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='gallery-item'>
                <div className='hover-bg'>
                  <div className='hover-text'>
                    <h4>Milking Time</h4>
                  </div>
                  <img
                    srcset='img/gallery/06-Mandatory-Hands-In-Photo-small.jpeg 480w, img/gallery/06-Mandatory-Hands-In-Photo.jpeg 1080w'
                    sizes='50vw'
                    className='img-gallery'
                    alt='Mandatory Hands-In Cheer Shot'
                  />
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='gallery-item'>
                <div className='hover-bg'>
                  <div className='hover-text'>
                    <h4>5-aside Footy</h4>
                  </div>
                  <img
                    srcset='img/gallery/07-5-aside-small.jpeg 480w, img/gallery/07-5-aside.jpeg 1080w'
                    sizes='50vw'
                    className='img-gallery'
                    alt='5-aside football'
                  />
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='gallery-item'>
                <div className='hover-bg'>
                  <div className='hover-text'>
                    <h4>Bike Gang</h4>
                  </div>
                  <img
                    srcset='img/gallery/08-Monopoly-Sunday-small.jpeg 480w, img/gallery/08-Monopoly-Sunday.jpeg 1080w'
                    sizes='50vw'
                    className='img-gallery'
                    alt='Monopoly bike ride on Easter Sunday 2021'
                  />
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='gallery-item'>
                <div className='hover-bg'>
                  <div className='hover-text'>
                    <h4>We got crabs</h4>
                  </div>
                  <img
                    srcset='img/gallery/09-Tribal-Gathering-Saturday-Night-Crabs-small.jpg 480w, img/gallery/09-Tribal-Gathering-Saturday-Night-Crabs.jpg 1080w'
                    sizes='50vw'
                    className='img-gallery'
                    alt='Party-winning Tribal Gathering crabs'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
